<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <el-form-item label="文章标题" prop="title">
        <el-col :xl="10">
          <el-input
            placeholder="请输入文章标题"
            v-model="ruleForm.title"
            clearable
          />
        </el-col>
      </el-form-item>
      <el-form-item label="文章来源" prop="originAuthor">
        <el-col :xl="10">
          <el-input
            placeholder="文章来源"
            v-model="ruleForm.originAuthor"
            clearable
          />
        </el-col>
      </el-form-item>
      <el-form-item label="文章标签" prop="dynamicId">
        <el-select
          v-model="ruleForm.labelId"
          multiple
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="i in tagsList"
            :key="i.labelId"
            :label="i.labelName"
            :value="i.labelId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文章封面" prop="photos">
        <el-upload
          :action="$store.state.uploadingUrl"
          list-type="picture-card"
          class="avatar-coverImg"
          :class="{ disabled: uploadDisabled }"
          :limit="1"
          :on-progress="handProgress"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
          :file-list="fileList"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <div class="h100p d-flex align-items-center">
          <p>建议上传750x600，格式可为png、jpg或jpeg</p>
        </div>
      </el-form-item>
      <el-form-item label="文章内容" prop="details">
        <quill-editor
          style="height: 100%"
          v-model="ruleForm.details"
          ref="myQuillEditor"
          :options="editorOption"
        />
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="ruleForm.createTime"
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="是否发布" prop="isDrafts">
        <el-switch
          v-model="ruleForm.isDrafts"
          active-text="发布"
          inactive-text="关闭"
        />
        <span
          class="ml10"
          style="vertical-align: middle; color: rgb(253, 99, 78)"
          >关闭状态即保存到"草稿箱"</span
        >
      </el-form-item>
      <el-form-item label="是否置顶" prop="isTop">
        <el-switch
          v-model="ruleForm.isTop"
          active-text="是"
          inactive-text="否"
        />
      </el-form-item>
      <el-form-item label="开启探索" prop="">
        <el-switch
          v-model="isExplore"
          active-text="是"
          inactive-text="否"
          @change="isExploreChange"
        />
      </el-form-item>
      <el-form-item label="绑定探索" prop="" v-if="ruleForm.isExplore">
        <el-select
          v-model="ruleForm.exploreId"
          placeholder="请选择需要绑定的探索程序"
          @change="exploreChange"
        >
          <el-option
            v-for="item in exploreList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template> 

<script>
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = "请填写文章内容";
//探索列表
import { exploreQueryPage } from "@/api/explore";
import { labelQueryPage } from "@/api/articleManagement";
export default {
  data() {
    var valiIcon = (rule, value, callback) => {
      // 图片验证
      if (!this.ruleForm.photos) {
        callback();
      } else {
        callback();
      }
    };
    return {
      editorOption: quillConfig,
      disabled: false,
      ruleForm: {
        title: "",
        originAuthor: "",
        dynamicId: "",
        details: "",
        isDrafts: false,
        photos: "",
        isExplore: false,
        explore: "",
      },
      fileList: [],
      rules: {
        title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
        photos: [{ required: true }],
        isDrafts: [{ required: true, message: "请输入文章内容" }],
        details: [{ required: true, message: "请输入文章内容" }],
      },
      exploreList: [],
      tagsList: [],
      defaultTime:'',
      isExplore:false,
    };
  },
  components: {
    quillEditor,
  },
  computed: {
    uploadDisabled() {
      return this.ruleForm.photos !== "";
    },
  },
  mounted() {
    this.getExploreList();
    this.getTagsList();
  },
  methods: {
    // 获取分类列表
    getTagsList() {
      let data = {
        pageNum: 1,
        pageSize: 1000,
      };
      labelQueryPage(data).then((res) => {
        this.tagsList = res.data.records;
        this.tagsList.forEach((el) => {
          el.labelId = String(el.labelId);
        });
      });
    },
    getExploreList() {
      exploreQueryPage({ pageSize: 100, pageNum: 1 }).then((res) => {
        this.exploreList = res.data.records;
      });
    },
    isExploreChange(e) {
      console.log(e, 999999);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.photos = "";
    },
    handProgress(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.photos = `https://cdn-oss.jquen.com/${response.data}`;
    },
    submitForm(payload) {
      if (!this.ruleForm.photos) {
        return this.$message.error("请上传文章封面");
      }
      if (!this.ruleForm.details) {
        return this.$message.error("请填写文章内容");
      }
      if (this.ruleForm.isExplore && this.ruleForm.exploreId == "") {
        return this.$message.error("请选择要绑定的探索内容");
      }
      this.ruleForm.isExplore = this.isExplore ? "1" : "0";
      this.ruleForm.labelId = this.ruleForm.labelId.join();
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          data.isDrafts = data.isDrafts ? 0 : 1;
          data.isTop = data.isTop ? 1 : 0;
          // if(payload) data.isDrafts = 0 ;
          console.log(data, "提交的数据");
          this.$emit("submitForm", data);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    exploreChange(e) {
      console.log(e, "选择");
      this.exploreList.forEach((el) => {
        if (el.id == e) {
          this.ruleForm.exploreUrl = el.url;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
::v-deep .avatar-coverImg .el-upload--picture-card {
  width: 200px;
  height: 160px;
}

::v-deep .avatar-coverImg .el-upload-list__item {
  width: 200px;
  height: 160px;
}
</style>